import { inject, Injector, runInInjectionContext } from '@angular/core';
import { Router } from '@angular/router';
import { CrmTranslateService } from 'common-module/translate';
import { DateTime } from 'luxon';
import { Observable, of } from 'rxjs';

import { EventModel } from '~/api/events/event.model';
import { FormCategory } from '~/api/forms/model/form.category';
import { FormType } from '~/api/forms/model/form.type';
import { TocFollowUpVisitTodoModel } from '~/api/todo/model/toc-follow-up-visit-todo.model';
import { UserModel } from '~/api/user/model/user.model';
import { TableConfig, TableData } from '~/shared/components/table/table.model';
import { TableProvider } from '~/shared/components/table/table.provider';
import { tableSelectionModalFactory } from '~/shared/modal/table/table-selection-modal';
import { formatDate, formatTime } from '~/shared/utils/date-time';
import { parseDateTime } from '~/shared/utils/parse-date-time';

import { formatAfterExpirationFactory } from '../factories/format-after-expiration.factory';
import { formatBeforeExpirationFactory } from '../factories/format-before-expiration.factory';
import { TodoItem } from '../todo.item';
import { ExtendedTocFollowUpVisitTodoModel } from '../types/toc-follow-up-visit.todo';

type SelectRow = { id: FormType; name: string; type: string };

/**
 * Class which maps toc follow-up visit TO-DOs
 */
export class TocFollowUpVisitTodosResolver {
  private translate = inject(CrmTranslateService);
  private injector = inject(Injector);
  private router = inject(Router);
  private tableSelectionModal = tableSelectionModalFactory();
  private formatBeforeExpiration = formatBeforeExpirationFactory();
  private formatAfterExpiration = formatAfterExpirationFactory();

  mapToExtendedTodo(
    todo: TocFollowUpVisitTodoModel,
    users: UserModel[],
    events: EventModel[],
  ): ExtendedTocFollowUpVisitTodoModel {
    const user = users.find(({ _id }) => _id === todo.meta.user);
    const event = events.find(({ _id }) => _id === todo.event);

    return { ...todo, user, event };
  }

  mapToItem(
    todo: ExtendedTocFollowUpVisitTodoModel,
  ): TodoItem<ExtendedTocFollowUpVisitTodoModel> {
    const { user, meta, _id, startTime, tags } = todo;

    if (!user) {
      throw new Error(`No user for todo '${todo._id}'`);
    }

    return {
      id: _id,
      type: 'actual',
      user,
      icon: 'components-line',
      category: this.translate.get('todos.category.toc'),
      title: this.translate.get('todos.tocFollowUpVisit.title'),
      messages: [
        {
          message: this.translate.get('todos.tocFollowUpVisit.within', {
            count: this.resolveWithinCount(tags),
          }),
        },
        {
          message: this.translate.get('todos.tocFollowUpVisit.dischargeDate', {
            date: formatDate(meta.dischargeDate),
          }),
        },
      ],
      expireAt: parseDateTime(startTime),
      isActionVisible: (item) =>
        item.expireAt.startOf('day') <= DateTime.now().startOf('day'),
      resolveStatus: ({ expireAt }) => {
        const isPast = expireAt.endOf('day') < DateTime.now().startOf('day');
        return isPast ? 'overdue' : 'active';
      },
      resolveExpiration: ({ expireAt, model }) => {
        const now = DateTime.now();

        if (expireAt.startOf('day') > now.startOf('day')) {
          return this.formatBeforeExpiration(
            expireAt.diff(now, [
              'weeks',
              'days',
              'hours',
              'minutes',
              'seconds',
              'milliseconds',
            ]),
          );
        }

        const eventEndTime = parseDateTime(model.event?.endTime);

        if (!eventEndTime) {
          return '?';
        }

        if (now > eventEndTime) {
          return this.formatAfterExpiration(
            now.diff(eventEndTime, [
              'weeks',
              'days',
              'hours',
              'minutes',
              'seconds',
              'milliseconds',
            ]),
          );
        }

        const expireAtMinutesBeforeNow = expireAt.diff(now, 'minutes').minutes;

        if (
          (expireAtMinutesBeforeNow > 0 && expireAtMinutesBeforeNow <= 15) ||
          (now > expireAt && now < eventEndTime)
        ) {
          return this.translate.get('generic.now');
        }

        return this.translate.get('todos.expiration.at', {
          date: formatTime(expireAt),
        });
      },
      action: {
        id: `select-form`,
        title: 'todos.completeForm',
        icon: 'icons:document-3-line',
        size: 'small',
        type: 'primary',
        action: () =>
          this.tableSelectionModal({
            title: 'generic.selectForm',
            confirm: 'generic.continue',
            provider: this.getTableProvider(),
            action: (value) => {
              this.router
                .navigate(
                  [
                    meta.organization,
                    'users',
                    user._id,
                    'forms',
                    'new',
                    value[0],
                  ],
                  { queryParams: { admission: meta.admission } },
                )
                .then(() => {});
            },
          }),
      },
      model: todo,
    };
  }

  private resolveWithinCount(tags: string[]) {
    const tag = tags.find((t) => t.endsWith('_days'));
    return tag?.match(/\d+/)?.[0] ?? '-';
  }

  private getTableProvider(): TableProvider<SelectRow> {
    const translateRef = this.translate;

    return runInInjectionContext(this.injector, () => {
      return new (class extends TableProvider<SelectRow> {
        protected getConfig(): Observable<TableConfig<SelectRow>> {
          return of({
            maxSelection: 1,
            rowClickStrategy: 'select',
            columns: [
              { id: 'name', name: 'generic.formName' },
              { id: 'type', name: 'generic.type' },
            ],
          });
        }

        protected loadDataFn(): Observable<TableData<SelectRow>> {
          const forms: { id: FormType; category: FormCategory }[] = [
            { id: 'super-virtual-visit-note', category: 'soap-note-medical' },
            { id: 'virtual-visit-note', category: 'soap-note-medical' },
            { id: 'initial-psychiatric-assessment', category: 'soap-note-bh' },
            { id: 'psychiatric-assessment', category: 'soap-note-bh' },
          ];

          return of({
            rows: forms.map(({ id, category }) => ({
              id,
              name: translateRef.get(`form.title.${id}`),
              type: translateRef.get(`form.categories.${category}`),
            })),
          });
        }
      })();
    });
  }
}
