import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CrmModalRefDirective } from 'common-module/modal';
import { CrmTranslateMessage } from 'common-module/translate';

import { TableSelectionComponent } from '../../components/table-selection/table-selection.component';
import { TableRow } from '../../components/table/table.model';
import { TableProvider } from '../../components/table/table.provider';

@Component({
  standalone: true,
  selector: 'app-table-selection-modal',
  template: `
    <app-table-selection
      [provider]="data.provider"
      [confirmTitle]="data.confirm"
      (cancelled)="dismiss()"
      (confirmed)="submit($event)"
    ></app-table-selection>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TableSelectionComponent],
})
export class TableSelectionModalComponent<
  Row extends TableRow,
> extends CrmModalRefDirective<
  string[],
  { provider: TableProvider<Row>; confirm: CrmTranslateMessage }
> {}
