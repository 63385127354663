import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { CrmTranslateService } from 'common-module/translate';
import { DateTime } from 'luxon';

import { AssessmentTodoModel } from '~/api/todo/model/assessment-todo.model';
import { UserModel } from '~/api/user/model/user.model';
import { parseDateTime } from '~/shared/utils/parse-date-time';
import { BaseTodoResolver } from '~/shared/crm/layout/right-sidebar/todos/resolvers/base-todo.resolver';
import { formatBeforeExpirationFactory } from '~/shared/crm/layout/right-sidebar/todos/factories/format-before-expiration.factory';
import { formatAfterExpirationFactory } from '~/shared/crm/layout/right-sidebar/todos/factories/format-after-expiration.factory';

import { TodoItem } from '../todo.item';
import { ExtendedAssessmentTodoModel } from '../types/assessment.todo';

/**
 * Class which resolves and maps assessment TO-DOs
 */
export class AssessmentTodosResolver extends BaseTodoResolver {
  private router = inject(Router);
  private translate = inject(CrmTranslateService);
  private formatBeforeExpiration = formatBeforeExpirationFactory();
  private formatAfterExpiration = formatAfterExpirationFactory();

  mapToExtendedTodo(
    todo: AssessmentTodoModel,
    users: UserModel[],
  ): ExtendedAssessmentTodoModel {
    return {
      ...todo,
      user: users.find(({ _id }) => _id === todo.meta.user),
    };
  }

  mapToItem(
    todo: ExtendedAssessmentTodoModel,
  ): TodoItem<ExtendedAssessmentTodoModel> {
    const { user, _id, endTime, meta, type } = todo;

    if (!user) {
      throw new Error(`No user for todo '${_id}'`);
    }

    return {
      id: _id,
      type: 'actual',
      user,
      category: this.translate.get('todos.category.forms'),
      icon: 'document-3-line',
      expireAt: parseDateTime(endTime),
      messages: [
        { message: this.translate.get(`todos.completeAssessment.${type}`) },
      ],
      title: this.translate.get('todos.completeAssessment.title', {
        assessment: this.translate.get(
          `todos.completeAssessment.type.${meta.record.type}`,
        ),
      }),
      resolveStatus: ({ expireAt }) =>
        expireAt < DateTime.now() ? 'overdue' : 'active',
      resolveExpiration: ({ expireAt }) => {
        const now = DateTime.now();

        if (expireAt > now) {
          return this.formatBeforeExpiration(
            expireAt.diff(now, [
              'weeks',
              'days',
              'hours',
              'minutes',
              'seconds',
              'milliseconds',
            ]),
          );
        }

        return this.formatAfterExpiration(
          now.diff(expireAt, [
            'weeks',
            'days',
            'hours',
            'minutes',
            'seconds',
            'milliseconds',
          ]),
        );
      },
      action: {
        id: `fill-assessment-${meta.record.type}`,
        title: `todos.completeForm`,
        icon: 'icons:document-3-line',
        size: 'small',
        type: 'primary',
        action: () => {
          this.router
            .navigate([
              meta.organization,
              'users',
              user._id,
              'forms',
              'new',
              meta.record.type,
            ])
            .then(() => {});
        },
      },
      dropdownActions: [this.getMarkAsNotApplicableAction(_id)],
      model: todo,
    };
  }
}
