import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
} from '@angular/core';
import { CrmLoadingComponent } from 'common-module/common';

import { InfiniteScrollDirective } from '~/shared/directives/infinite-scroll.directive';

import { IsTodoItemActualPipe } from './pipes/is-todo-item-actual.pipe';
import { IsTodoItemSuccessPipe } from './pipes/is-todo-item-success.pipe';
import { TodoSuccessComponent } from './todo-success.component';
import { TodoComponent } from './todo.component';
import { ExtendedTodoModel, TodoItemActual } from './todo.item';
import { TodoRedirect } from './todo.redirect';
import { TodosHeaderComponent } from './todos-header.component';
import { TodosProvider } from './todos.provider';

@Component({
  standalone: true,
  selector: 'app-todos',
  template: `
    <app-todos-header></app-todos-header>

    <div
      class="ehr-todos__list"
      appInfiniteScroll
      (endScroll)="handleEndScroll()"
    >
      @for (item of provider.items(); track item.id) {
        @if (item | isTodoItemActual; as actual) {
          <app-todo [item]="actual" (click)="redirectByTodo(actual)"></app-todo>
        }

        @if (item | isTodoItemSuccess; as success) {
          <app-todo-success [item]="success"></app-todo-success>
        }
      }

      @if (provider.isLoading()) {
        <crm-loading size="small"></crm-loading>
      }
    </div>
  `,
  imports: [
    CrmLoadingComponent,
    TodoComponent,
    IsTodoItemActualPipe,
    IsTodoItemSuccessPipe,
    TodoSuccessComponent,
    TodosHeaderComponent,
    InfiniteScrollDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodosComponent {
  @HostBinding('class')
  protected readonly hostClass = 'ehr-todos';

  protected provider = inject(TodosProvider);

  private redirect = new TodoRedirect();

  protected redirectByTodo(item: TodoItemActual<ExtendedTodoModel>) {
    this.redirect.redirect(item);
  }

  protected handleEndScroll() {
    this.provider.loadNext().subscribe();
  }
}
